body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: "#red";
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: "#red";
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s "ease-in-out";
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s "ease-in-out", opacity 0.3s "ease-in-out";
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s "ease-out", box-shadow 0.3s "ease-out";
  border: none;
  color: "#red";
  transition: box-shadow 0.3s "ease-out", transform 0.3s "ease-out";
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: 'white';
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Nav3_0.jzih1dpqqrg-editor_css {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.ant-menu-item > .item-sub-item > .jzj81c9wabh-editor_css {
  float: none;
  margin: 4px 0px 0px;
}
.ant-menu > .ant-menu-item > .jzj8295azrs-editor_css {
  padding: 0;
}
#Feature0_0 .ant-col > .content0-block-item > .jzj8xt5kgv7-editor_css {
  font-size: 18px;
}
#Nav3_0 .home-page > .jzjgnya1gmn-editor_css {
  width: 110px;
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .content0-block-icon {
  width: 10px;
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .jzjgrlz134-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjgrlz134-editor_css {
  width: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjncn210ql-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndq0dueg-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndsyw8sf-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndw5oerk-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne24af8c-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne54fwqm-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-row > .ant-col > .jzjgrrupf2c-editor_css {
  padding: 0;
}
#Feature0_0 div > .ant-row > .content0-block {
  min-height: 340px;
  padding: 24px;
  transition: box-shadow 0.45s ease 0s;
}
#Feature0_0 div > .ant-row > .content0-block:hover {
  box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
}
#Footer1_0 .ant-row > .ant-col > .jzl0qcpyjra-editor_css {
  width: 100px;
}
#Footer1_0 .ant-col > div > .jzl0u1bko6-editor_css {
  margin: 16px 0 0;
}
